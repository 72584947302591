// Main Colors

$app-background-color: #F8FAFC;
$app-background-color-dark: #145D7F;
$app-menu-background-color: #ffffff;
$app-card-background-color: #ffffff;
$agray: #AAB6C4;
$light-gray: #D5DBE2;
$medium-gray: #AAB6C4;
$success: #85BA2F;

// Buttons //
$color-primary: #EEB32F;
$color-secondary: #6AA0BF;
$color-success: #85BA2F;
$color-denied: #CB7575;

$btn-go: #85BA2F;
$btn-go-hover: #77A72A;

$btn-primary: #EEB32F;
$btn-primary-hover: #D4A12A;

$btn-activate: #85BA2F;
$btn-activate-hover: #689322;

$btn-scansearch: #ffffff;
$btn-scansearch-hover: #ffffff;

$btn-bMenu: #6AA0BF;

// Fonts //
$font-color-light: #ffffff;
$font-color-dark: #333333;
$font-color-gray: #AAB6C4;
$font-color-input-placeholder: #C2CBD5;
$font-link-light: #80C1E0;

// Layout //

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  color: $font-color-dark;
  background-color: $app-background-color;
  height: 100%;
}

.background-dark {
  background-color: $app-background-color-dark;
  color: $font-color-light;
  height: calc(100vh + 0px);
  box-sizing: border-box;
}

.app-body {
  margin-top: 52px;
}

.main .container-fluid {
  padding: 0 10px;
}

h1 {
     font-size: 1.7em;
     margin-bottom: 20px;
     font-weight: 500;
}

h2 {
     font-size: 1.1em;
     margin-bottom: 20px;
     font-weight: 500;
}

h4 {
     font-size: .85rem;
     color: $color-primary;
     font-weight: 600;
     margin-top: -15px}

a, a:hover {color: #7DC1E0;}

.row > .col,
.row > [class*="col-"] {
      padding-right: 10px;
      padding-left: 10px;
}

.container {
     padding-right: 20px;
     padding-left: 20px;
}

// Cards //

.card-title {font-size: 1rem; margin-bottom: .25rem;}
.card-title.large {font-size: 1.1rem; font-weight: 600; margin-bottom: 2px;}
.card-subtitle {font-size: .85rem; color: $font-color-gray; font-weight: 400;}
.card-subtitle.white {color: $font-color-light;}

.card {
     border-radius: 16px;
     box-shadow: 5px 10px 20px 10px rgba(39, 53, 154, .15);
     border: 0px;
     margin: 10px 0px;
     background-color: $app-card-background-color;
     &:last-child {
       margin: 10px 0px 30px 0px;
     }
}

.card-body {
  padding: 1rem 1.25rem;
}

.no-scans.card-body {
  display: inline;
  line-height: 40px;
}

.card.default {
  background-color: white;
}

.card.success {
     background-color: $color-success;
     color: $font-color-light;
}

.card.denied {
     background-color: $color-denied;
     color: $font-color-light;
}

.card.alert {
     background-color: $font-color-gray;
     color: $font-color-light;
}

.card.success .card-title, .card.success .card-subtitle,
.card.denied .card-title, .card.denied .card-subtitle,
.card.notfound .card-title, .card.notfound .card-subtitle
     {color: $font-color-light;}

.scanned {
  flex: 0.5;
}

.scanned-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-end;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
}

.card.scanned {
  bottom: 0px;
  width: 100%;
  margin: 0px;
  line-height: 1.5em;
  border-radius: 0px;

  &.manual {
    min-height: 261px;
    max-height: 90%;
    margin: 0;
    width: 100%;
    z-index: 999;
  }

  &.busy {
    padding: 100px;
  }
}

.compliant.card-title:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  margin-left: 7px;
  content: "\f0a9";
}

.present.card-title:before {
  color: white;
  font-family: "Font Awesome 5 Pro";
  margin-right: 7px;
  content: "\f058";
}

.employer.card-title {
  font-weight: 400;
}

.checkout.card-title:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  margin-right: 7px;
  content: "\f0a8";
}

.denied.card-title:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  margin-right: 7px;
  content: "\f057";
}

.detail-position {
  position: absolute;
  top: 90px;
}

.card-body-scroll {
  flex-direction: column;
  overflow-y: auto;
  padding: 5px 10px 0px 10px;
}

.card-detail-header {
  color: #FFFFFF;
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  padding: 15px;
}
// Status on Cards (success, denied) //

.CheckSuccess {
     border-radius: 50%;
     position: absolute;
     top: 22px;
     right: 20px;
     font-weight: 600;
     font-size: 1.6em;
}

.CheckSuccess.present {
  .far.fa-check-circle:before {
    color: $color-success;
  }
}

.CheckSuccess.absent {
  .far.fa-circle:before {
    color:  $font-color-gray;
  }
}

.CheckSuccess.success:before {
     color: $color-success;
     font-family: "Font Awesome 5 Pro";
     content: "\f058";
}

.CheckSuccess.denied:before {
     color: $color-denied;
     font-family: "Font Awesome 5 Pro";
     content: "\f057";
}

.CheckSuccess.compliant:before {
     color: $font-color-gray;
     font-family: "Font Awesome 5 Pro";
     content: "\f058";
     opacity: .5;
}

.CheckSuccess.not-compiant:before {
     color: $font-color-gray;
     font-family: "Font Awesome 5 Pro";
     content: "\f057";
     opacity: .5;
}

// Small Avatar //

.sm-avatar {
     vertical-align: middle;
     width: 40px;
     height: 40px;
     border-radius: 50%;
     float: left;
     margin-right: 10px;
     object-fit: cover;
}

.lg-avatar {
     width: 100px;
     height: 100px;
     border-radius: 50%;
     object-fit: cover;
}

// Scanner Icon //

.icon-barcode:before {
     font-family: "Font Awesome 5 Pro";
     font-weight: 600;
     content: "\f463";
     font-size: 1.2rem;
     line-height: 1.0rem;
     float: left;
     margin-right: 10px;
}

.icon-goback:before {
     font-family: "Font Awesome 5 Pro";
     font-weight: 600;
     content: "\f351";
     font-size: 1.2rem;
     line-height: 1.0rem;
     float: left;
     margin-right: 10px;
     padding-bottom: 1px;
}

.icon-barcode:before {
     font-family: "Font Awesome 5 Pro";
     font-weight: 600;
     content: "\f463";
     font-size: 1.2rem;
     line-height: 1.0rem;
     float: left;
     margin-right: 10px;
}

.icon-camera:before {
     font-family: "Font Awesome 5 Pro";
     font-weight: 600;
     content: "\f030";
     font-size: 2.2rem;
     line-height: 2.8rem;
     float: left;
     margin-right: 10px;
}

.icon-no-camera {
     background: url("../assets/img/icon-no-camera.svg") no-repeat;
     height: 40px;
     width: 51px;
     max-height: 40px;
     margin: 0 auto;
}

.icon-alert {
     width: 100px;
     height: 100px;
}

// Support //

.card-body.support {line-height: 40px;}

.support-icons {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $color-secondary;
  float: left;
  margin-right: 10px;
}

.support-icons:before {

  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: white;
  width: 100%;
  display: inline-block;
  position: relative;
  font-family: "Font Awesome 5 Pro";
}

.support-icons.phone:before {content: "\f095";}
.support-icons.email:before {content: "\f0e0";}

// Sign In //

.signin-wrapper {
  height: 100vh;
  // height: -webkit-fill-available;
  color: #ffffff;
  box-sizing: border-box;
  background: url("../assets/img/signin-background.svg") no-repeat;
  background-position: 0% calc(-20vh + 50%);
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
}

.signin-wrapper .container {
     padding-right: 45px !important;
     padding-left: 45px !important;
}

.logo-wrapper {
    padding-top: calc(10vh + 0%);
    float: right;
    font-size: 2em;
}

.logo-wrapper-2 {
    padding-top: calc(10vh + 0%);
    float: middle;
    font-size: 2em;
}

.logo-tappi {
     background: url("../assets/img/brand/logo-tappi.svg") no-repeat;
     height: 84px;
     width: 84px;
     background-size: cover;
     max-height: 84px;
}

.logo-1 {
  font-weight: 600;
}

.logo-2 {
  font-weight: 200;
}

.logo-pyvot {
     background: url("../assets/img/brand/logo-pyvot.svg") no-repeat;
     height: 18px;
     width: 100px;
     background-size: cover;
     max-height: 100px;
     bottom: 30px;
     margin: 0 auto;
}

.logo-gatecheck-wrapper {
     padding-top: calc(27vh + 0%);
     position: absolute;
}

.logo-gatecheck {
     font-size: 1.2rem;
     font-weight: 600;
     margin-bottom: -5px;
}

.signin-subtext {
     color: #ffffff;
     opacity: .65;
     font-weight: 300;
     padding-top: 10%;
}

.form-wrapper {
  padding-top: calc(38vh + 0px);

  .form {
    position: relative;

    .form-error {
      position: absolute;
      color: #EEB32F;
      opacity: 0;
      top: -50px;

      &.show {
        opacity: 1;
      }
    }
  }
}

.signin-wrapper input::placeholder {
     color: #7DC1E0 !important;
}

.signin-wrapper .form-control {
     background-color: #186E96 !important;
     border-color: #186E96 !important;
     box-shadow: 5px 10px 20px 10px rgba(39, 53, 154, .15);
     color: #ffffff !important;
}

.signin-wrapper .input-username-wrapper:before {
    content: "\f2bd";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: #7DC1E0;
    font-size: 1.5em;
    margin-left: 1em;
    position: absolute;
    top: 7px;
    left: 0px;
}

.form-control.username {
     padding-left: 45px;
}

.signin-wrapper .input-password-wrapper:before {
    content: "\f084";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: #7DC1E0;
    font-size: 1.5em;
    margin-left: 1em;
    position: absolute;
    top: 7px;
    left: 0px;
}

.form-control.password {
     padding-left: 45px;
}

.signin-helps {
  margin: 20px 0px;
  color: $font-link-light;
}

a.register-link, a.register-link:hover {color: #EEB32F;}

// Inputs //

::placeholder {
     color: $font-color-input-placeholder !important;
}

.form-control {
     height: calc(2.2em + .75rem + 2px);
     border-radius: 1em;
}

.form-control.search {
     padding-left: 45px;
}

.input-search-wrapper {
     position: relative;
    width: 100%;
}

.input-search-wrapper:before {
    content: "\f002";
    font-family: "Font Awesome 5 Pro";
    color: $font-color-input-placeholder;
    font-size: 1.2em;
    margin-left: .5em;
    position: absolute;
    top: 10px;
    left: 0;
}

// .input-search-wrapper:after {
//   content: "\f464";
//   font-family: "Font Awesome 5 Pro";
//   color: $font-color-input-placeholder;
//   font-size: 1.2em;
//   margin-right: .5em;
//   position: absolute;
//   top: 10px;
//   right: 0;
// }

.scansearch-wrapper {
  position: absolute;
  top: 170px;
  z-index: 1050;
  width: calc(100% - 20px);
}

.input-scansearch-wrapper:before {
    content: "\f002";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: $font-color-light;
    font-size: 1.2em;
    margin-left: .7em;
    position: absolute;
    top: 10px;
    left: 0px;
    z-index: 1050;
}

.input-scansearch, .input-scansearch:active, .input-scansearch:focus {
  color: $font-color-dark;
  background-color: white;
  border: 0;
  padding: 1.7em;
  border-radius: 50px;
}

.form-control.input-scansearch {
     padding-left: 40px;
}

.input-scansearch-wrapper .input-group-append {
  margin-left: -5px;
}

// Buttons //

.btn {
    // font-weight: 600;
    border-radius: 16px;
    // padding: .8rem 1.9rem;
    // font-size: .9em;
    // margin: 0 5px;
    &.scan-action-btn {
       margin: 5px;
     }
 }

.btn.caret {
  &:after {
    font-family: "Font Awesome 5 Pro";
    content: "\f0d7";
    font-weight: 600;
    margin-left: 7px;
  }
}

.btn-go {
     background-color: $btn-go;
     border-color: $btn-go;
     color: $font-color-light;
     padding-left: 20px;
     padding-right: 20px;
     &:hover {
          background-color: $btn-go-hover;
          border-color: $btn-go-hover;
          color: $font-color-light;
     }
}

.btn-round {
     border-radius: 50px;
}

.btn-primary {
    color: $font-color-light;
    background-color: $btn-primary;
    border-color: $btn-primary;
    &:hover, &.focus {
         background-color: $btn-primary-hover;
         border-color: $btn-primary-hover;
         color: $font-color-light;
    }
}

.btn-activate {
    color: $font-color-light;
    background-color: $btn-activate;
    border-color: $btn-activate;
    &:hover, &.focus {
         background-color: $btn-activate-hover;
         border-color: $btn-activate-hover;
         color: $font-color-light;
    }
}

.btn-sm {
    padding: .5rem 1.2rem;
    border-radius: 50px;
}

.btn-lg {
    padding: .75rem 1rem;
    border-radius: 50px;
    font-size: 1.1em;
}

.btn-white-outline {
    border: 2px solid #ffffff;
    background-color: transparent;
    color: $font-color-light;
    &:hover, &.focus {
         background-color: transparent;
         border: 2px solid #ffffff;
       }
}

.btn-green-outline {
    border: 2px solid $color-success;
    background-color: transparent;
    color: $color-success;
    &:hover, &.focus {
         background-color: transparent;
         border: 2px solid #ffffff;
       }
}

.btn-navlink {
    padding: .5rem 1rem;
    font-size: 1.2em;
}

.btn-transparent {
  background-color: transparent;
  border-color: transparent;
  border: 1px;
  &:hover, &.focus, &:active {
    color: $font-color-light;
  }
}

.btn-scansearch {
  color: $font-color-dark;
  background-color: $btn-scansearch;
  border-color: $btn-scansearch;
  font-size: 1.2em;
  &:hover, &.focus {
       background-color: $btn-scansearch-hover;
       border-color: $btn-scansearch-hover;
       color: $font-color-light;
     }
}

.btn-outline {
  background-color: #ffffff !important;
  border-color: #C2CBD5 !important;
  font-weight: normal;
  font-size: 1.1em;
  text-align: left;
  padding: .8rem 1.2rem;
}

.navbar-toggler {
  outline: none !important;
}

.btn:focus, .btn.focus {
  box-shadow: 0 0 0 0rem rgba(32, 168, 216, 0);
}

// Top Navbar //

.top-spacer {
     height: 0px;
@media (max-width: 767px) {
     height: 82px;
     }
}

.fixed {
     z-index: 999;
     position: fixed;
     top: 0px;
     width: 100%;
}

.bg-light {
     background-color: transparent !important;
}

.navbar-toggler {
     border: 3px;
     position: fixed;
     right: 20px;
     top: 36px;
     z-index: 1000;
}

.navbar-toggler-icon {
      background: url("../assets/img/icon-nav.svg") center center no-repeat;
      width: 1.2em;
      height: 1.2em;
}


.navbar-wrapper {
     width: 100%;
          width: 270px;
          position: fixed;
          top: 65px;
          right: 0px;
          z-index: 999;
}

.collapse.show.navbar-collapse, .collapsing.navbar-collapse {
     padding: 20px;
     z-index: 999;
     background-color: $app-menu-background-color;
     border-radius: 16px;
     box-shadow: 5px 10px 20px 10px rgba(39, 53, 154, .15);
     }

.direction-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 2.2rem !important;
  height: 100vh;
}

.navbar-header {
    background-color: $app-background-color;
    height: 60px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
    width: 100%;
}

.navbar-header.transparent {
  background-color: #000000;
  opacity: .5;
  color: $font-color-light;
}

.navbar-light .navbar-nav .nav-link {
     color: $font-color-dark;
     line-height: 32px;
}

.navbar-icons:before {
     font-family: "Font Awesome 5 Pro";
     font-weight: 600;
     font-size: 1.3em;
     margin-right: 7px;
     color: #AAB6C4;
     float: left;
}

.navbar-icons.users:before {content: "\f0c0";}
.navbar-icons.support:before {content: "\f059";}
.navbar-icons.logout:before {content: "\f2f5";}

.nav-item {
     margin-right: 20px;
}

.nav-item:last-child {
     margin-right: 0px;
}

a .navigation-back {
  color: white;
  font-size: 1.2em;
}

.navigation-back:before {
    content: "\f053";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    margin-right: 10px;
  }

.nav-row-position {
  position: relative;
  top: 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

// Bottom Navbar //

.bMenu-wrapper {
     border-radius: 16px 16px 0 0;
     height: 73px;
     background-color: $app-menu-background-color;
     z-index: 999;
     position: fixed;
     bottom: 0px;
     width: 100%;
     box-shadow: 5px 10px 20px 10px rgba(39, 53, 154, .15);
     text-align: center;
}

.bMenu-item-wrapper {
     line-height: 53px;
     margin-top: 7px;
}

.btn.btn-bMenu {
     background-color: transparent;
     color: $font-color-gray;
     border-radius: 12px;
}

.btn.btn-bMenu.active {
     background-color: $btn-bMenu;
     color: $font-color-light;
}

.btn.btn-bMenu:before {
     font-weight: 600;
     font-family: "Font Awesome 5 Pro";
     content: "\f464";
     font-size: 1.4em;
}

.btn.btn-bMenu.scanner:before {content: "\f464";}
.btn.btn-bMenu.search:before {content: "\f002";}
.btn.btn-bMenu.location:before {content: "\f3c5";}
.btn.btn-bMenu.muster:before {content: "\f071";}
.btn.btn-bMenu.support:before {content: "\f059";}

.bottom-spacer {height: 93px;}

// Dropdowns //

.btn-block.dropdown-toggle:after {
  top: 23px;
  font-size: 1.3em;
  right: 15px;
  position: absolute;
}

.dropdown-menu {
  width: 100%;
  border-radius: 16px;
  border: 1px solid #C2CBD5;
}

.dropdown-menu .dropdown-item {
  border-bottom: 0px solid transparent;
}

// Modals //

.scan-search > .modal-content {
  background-color: transparent;
}

.modal-dialog {
  margin: 2.2rem;
}

.modal-content {
  border-radius: 16px;
  box-shadow: 5px 10px 20px 10px rgba(0, 0, 0, .15);
}

.modal-body {
  padding: 1rem 2rem;
  font-size: 1.2em;
  font-weight: 600;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.error-overlay-wrapper {
  padding-top: calc(50vh - 30%);
  text-align: center;
  color: $font-color-light;
  position: absolute;
  top: 0;
  z-index: 1050;
  height: 100%;
  width: 100%;
}

.error-overlay-message {

}

.error-icon:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f071";
  font-size: 3em;
  font-weight: 300;
}

.close-modal {
  color: $font-color-light;
  font-size: 1.8em;
  font-weight: 300;
  position: absolute;
  top: -20px;
  right: -30px;
  z-index: 1050;
}

// Error Messages //

.error-message-wrapper {
  height: calc(100vh + 0px);
  line-height: calc(100vh - 15vh);
  text-align: center;
}

.error-message {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.image-404 {
  background: url("../assets/img/image-404.svg") center center no-repeat;
  width: 100%;
  min-height: calc(50vh - 0px);
}

.image-500 {
      background: url("../assets/img/image-500.svg") center center no-repeat;
      width: 100%;
      min-height: calc(50vh - 0px);
}

// Loading //

.loading-wrapper {
  position: relative;
  top: calc(40vh - 0px);
}

// Scandit //

.scandit-logo {display: none;}
// .scandit-viewfinder {margin-bottom: 165px; margin-top: 65px; }

// Footer //

.signin-footer-wrapper {
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -50px;
}

.footer-powered-by {
  text-align: center;
  margin-bottom: 5px;
  color: $font-link-light;
  bottom: 45px;
  width: 100%
}

.scandit {

  .scandit-camera-switcher {
    top: 80px !important;
  }

  .scandit-torch-toggle {
    top: 80px !important;
  }
}

.muster-card-animate.absent {
  left: 0px;
  position: relative;


  &.true {
    left: 100px;
    transition: 0.2s all ease-in-out;
    opacity: 0;
  }
}

.muster-card-animate.present {
  right: 0px;
  position: relative;


  &.true {
    transition: 0.2s all ease-in-out;
    right: 100px;
    opacity: 0;
  }
}

// .modal {
//
//   &.manual-entry-wrapper {
//     display: flex !important;
//
//     .manual-entry-container {
//       align-self: center;
//       margin: 10px;
//
//       .manual-entry-content {
//         box-shadow: none;
//         border: none;
//
//         .manual-input {
//           align-items: center;
//           background: #e5e5e5;
//           border-radius: 15px;
//           padding: 0px 15px;
//
//           .sm-avatar {
//             width: 25px;
//             height: 25px;
//           }
//
//           #scansearch {
//             background: transparent;
//             padding-left: 0px;
//           }
//
//           #scansearch:focus {
//             outline: 0;
//             border: none;
//             box-shadow: none;
//             color: #c3c3c3
//           }
//
//           .btn-scansearch {
//             background: transparent;
//             border-color: transparent;
//           }
//         }
//       }
//     }
//   }
// }
